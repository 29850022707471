import * as React from 'react';
import Layout from 'components/layout/layout';
import Sitemap from 'components/sitemap/container';

export default ({ location }: any) => {
    return (
        <Layout location={location} title={'Sitemap'} tenantCss={'searchCssUrl'}>
            <Sitemap location={location}/>
        </Layout>
    );
};
