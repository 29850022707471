import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    sitemapContainer: {
        backgroundColor: theme.color.filtersBackground,
        minHeight: 'calc(100vh - 150px)',
    },
    header: {
        background: theme.color.background,
        backgroundPosition: 'center, right bottom',
        backgroundSize: 'cover',
        color: theme.color.text,
        paddingTop: '75px',
        paddingBottom: '80px',
        '& h1': {
            marginTop: '60px',
        }
    },
    linksContainer: {
        marginTop: '20px',
        marginBottom: '30px',
        '& > div':{
            display: 'flex',
            flexDirection: 'column',
            '& a': {
                width: 'fit-content',
                color: '#00ACD9',
                marginBottom: '4px',
                textDecoration: 'underline',
                '&:hover':{
                    opacity: 0.7,
                }
            }
        }
    },
});
