import { Fetch } from 'react-request';
import { TenantContext } from 'components/loadTenant/tenantContext';
import * as React from 'react';
import { GetStudyResultsRq } from 'components/fetch/models/searchResults';
import { GetStudyDetailsRq } from 'components/fetch/models/studyDetails';

export const GetStudyResults = ({
                                    children,
                                    afterFetch,
                                    request,
                                }: GetStudyResultsRq) => (
    <TenantContext.Consumer>
        {tenant => (
            <Fetch
                url={`${process.env.GATSBY_STAPI_URL}api/study/search?tenantId=${tenant.id}`}
                headers={{
                    'Content-Type': 'application/json;charset=UTF-8',
                }}
                body={JSON.stringify(request)}
                afterFetch={afterFetch}
                children={children}
                method={'POST'}
                lazy={false}
            />
        )}
    </TenantContext.Consumer>
);

export const GetStudyDetails = (props: GetStudyDetailsRq) => (
    <TenantContext.Consumer>
        {tenant => (
            <Fetch
                {...{
                    url: `${process.env.GATSBY_STAPI_URL}api/study?tenantId=${tenant.id}&uniqueStudyId=${props.studyId}`,
                    ...props,
                }}
            />
        )}
    </TenantContext.Consumer>
);

export const GetAllIds = ({ children }: {children:any})  => (
    <TenantContext.Consumer>
        {tenant => (
            <Fetch 
                url={`${process.env.GATSBY_STAPI_URL}api/study/ids/all?tenantId=${tenant.id}`}
                children={children}
            />
        )}
    </TenantContext.Consumer>
);
