import React from 'react';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from './container.style';
import { Container } from 'reactstrap';
import { GetAllIds } from 'components/fetch/stApi';

interface Props extends WithStyles<typeof styles> {
    location: Location;
}
class Sitemap extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

    }
    render = () => {
        const data = this.getAllStudyIds();
        const { classes } = this.props;
        return (
            <div className={classes.sitemapContainer}>
                <div className={classes.header}> 
                    <Container>
                        <h1>Sitemap</h1>
                    </Container>
                </div>
                <div className={classes.linksContainer}> 
                    <Container>
                    <a href='/'>Home</a>
                    <a href='/search'>Advanced Search</a>
                    <a href='/search-results'>Search Results</a>
                    <a href='https://www.who.int/' target='_blank' rel='noopener noreferrer'>World Health Organization</a>
                    <a href='https://www.nih.gov/' target='_blank' rel='noopener noreferrer'>National Institute of Health</a>
                    <a href='https://clinicaltrials.gov/' target='_blank' rel='noopener noreferrer'>ClinicalTrials.gov</a>
                    <a href='https://www.coronavirus.gov/' target='_blank' rel='noopener noreferrer'>Coronavirus.gov</a>
                    { data } 
                    </Container>
                </div>
            </div>
        );
    };

    private getAllStudyIds = () => {
    return (
        <GetAllIds> 
            {({ data, error } : { data:any, error:any }) => {
                if (data && data.Data) {
                    return data.Data.map((item: { UniqueIdentifier: any; }) => (<a href={`/details/?id=${item.UniqueIdentifier}`}>{`Trial Details Page, Identifier: ${item.UniqueIdentifier}`}</a>));
                }
                if (error) {
                    return 'Error fetching study results';
                }
                return null;
            }}
        </GetAllIds>)
    }
}


export default withStyles(styles)(Sitemap);
